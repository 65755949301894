import React from 'react';
import './App.css';
import CheckToken from './model/checkToken';
import { RouteComponentProps } from 'react-router';

type urlProps = {} & RouteComponentProps<{token: string}>;

const Share: React.FC<urlProps> = (props) => {
  return (
    <div className="msgBox">
      <p>Evolutionmapをインストールしたスマートフォン端末よりアクセスしてご利用ください。</p>
    </div>
  );
};
export default Share;
