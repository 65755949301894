const firebaseConfig = {
    apiKey: "AIzaSyD2iBKz1KnoUjElDaffG_A7TKzGxjoIqTU",
    authDomain: "emap-dev.firebaseapp.com",
    databaseURL: "https://emap-dev.firebaseio.com",
    projectId: "emap-dev",
    storageBucket: "emap-dev.appspot.com",
    messagingSenderId: "871937934107",
    appId: "1:871937934107:web:b1ec24ee00d46285bd49be"
};
export default firebaseConfig;
